import 'jquery';

jQuery(document).ready(($) => {

  $(document)
    .on('click', '.trigger-video', function () {
      let target = $(this).closest('div').find('.video-layer');
      if (target.length === 0) {
        let reviewsVideoLayer = $('.reviews-wrapper .video-layer');
        if (reviewsVideoLayer.length > 0) {
          target = reviewsVideoLayer;
        }
      }
      target.addClass('active');
    })
    .on('click', '.video-layer', function () {
      $(this).removeClass('active');
      $('.yt-player').each(function () {
        $(this).attr('src', $(this).attr('src'));
        return false;
      });
    })

});
